<template>
  <div v-if="total">
    <span>
      Hiển thị từ <strong>{{ currentPage * perPage - perPage + 1 }}</strong> đến
      <strong>{{ currentPage * perPage }}</strong> của <strong>{{ total }}</strong> dòng.
    </span>
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: ["total", "currentPage", "totalPage", "perPage"],
  data: () => ({}),
  methods: {},
};
</script>

<style scoped></style>
